import React, {useState, useEffect, Fragment} from 'react';
import flatstore from 'flatstore';
import { currentLang } from '../../../utils/functions';
import {getFarmshopProductList, getRelatedProducts} from '../../../utils/services';
import ProductCard from '../ProductCard';
import {isNil, isArray, includes} from 'lodash';
import W from '../../common/Word';
import parse from 'html-react-parser';
import FarmshopProductBox from '../../../pages/Farmshop/components/product';
import {Link} from 'react-router-dom';

function FarmshopHighlight({data, farmshop_module_data}) {
    const [items, setItems] = useState(null);
    let slugs = null;

    if (data && data.config && items) {
      const config = JSON.parse(data.config);
      const inStockItems = items.filter((item) => item.stock_status === "instock");
      if (config) {
        slugs = config.filter((slug) => inStockItems.some((item) => item.slug === slug)).slice(0, data.total_show ?? 6);
      } 
    } 
    useEffect(() => {
        farmshop_module_data = null;
    }, [])

    useEffect(() => {
        if(isNil(farmshop_module_data) && isNil(items)){
            getFarmshopProductList('farmshop_module_data');
        }
        if(farmshop_module_data && farmshop_module_data.length > 0 && isNil(items)){
            setItems(farmshop_module_data);
        } 
    }, [farmshop_module_data])


    // to be used for the conditionally rendering based on the body_position
    let body_position = data.body_position;
    
    // Set the empty body_position to 'left'
    if(body_position === null){
      body_position = 'left';
    }

    return (
      <Fragment>
        {items && isArray(items) && items.length > 0 && (
          <div className={"body-"+body_position}>
            {(() => {

              if (body_position === 'left' || body_position === 'right') {
                return (
                  <div className="container horizontal-ab-test">
                    <div className="row mf-content">
                      {data.body && (
                        <div className="col-lg-4 ml-1 mf-body">
                          {data.body && (
                            <div className="mf-html">
                              {parse(data.body)}
                            </div>
                          )}
                          {data.button_text && (
                            <div className="mf-btn">
                              <Link
                                to={data.button_link ?? "/" + currentLang()}
                                title={data.button_text}
                                className="mf-cta-btn btn btn--red btn--outline"
                              >
                                {data.button_text}
                              </Link>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="col-lg-7 mf-items">
                        <div className="row justify-content-center">
                        {slugs &&
                          slugs.map((slug, index) => {
                            const item = items.find(
                              (item) => item.slug === slug
                            );
                            if (!item) return null;

                              return (
                                <div
                                  key={"product_card_" + index}
                                  className="fs-item col-6 col-md-4 col-lg-4"
                                >
                                  <FarmshopProductBox
                                    key={index}
                                    data={item}
                                    view_button={true}
                                  />
                                </div>
                              );
                            })}
                        </div>
                        {data.button_text && (
                          <div className="mf-cta-btn text--center pt-3 d-lg-none">
                            <Link
                              to={data.button_link ?? "/" + currentLang()}
                              title={data.button_text}
                              className="btn btn--red btn--outline"
                            >
                              {data.button_text}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="x-scroll">
                    <div className="mf-content">
                      <h2 className="heading--h2 text--center mb-2 mb-md-3 px-4">
                        {data.title}
                      </h2>
                      {data.body && (
                        <div className="body my-3 mx-auto px-5">
                          {parse(data.body)}
                        </div>
                      )}
                    </div>
                    <div className="container">
                      <div className="row justify-content-center pb-3 pb-lg-0">
                        {slugs &&
                          slugs.map((slug, index) => {
                            const item = items.find(
                              (item) => item.slug === slug
                            );
                            if (!item) return null;

                            return (
                              <div
                                key={"product_card_" + index}
                                className="fs-item col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
                              >
                                <FarmshopProductBox
                                  key={index}
                                  data={item}
                                  view_button={true}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {data.button_text && (
                      <div className="cta text--center px-3">
                        <Link
                          to={data.button_link ?? "/" + currentLang()}
                          title={data.button_text}
                          className="btn btn--red btn--outline"
                        >
                          {data.button_text}
                        </Link>
                      </div>
                    )}
                  </div>
                );
              }
            })()}
          </div>
        )}
      </Fragment>
    );
}

export default flatstore.connect(['farmshop_module_data'])(FarmshopHighlight);
